<template>
    <!-- User Profile Health Info Tab Content View From Account Profile -->
    <div>
        <div class="tabs-content-secondblock right-tab">
            <div>
                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                    <div class="breadcrumbactive ml-2">
                        <div>
                            <span>Profile</span>
                            <div>Health</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="health-info mr-2" style="margin-left: 10px;">
                <div class="" style="background-color: #fff;border-radius: 0px 6px 6px 0px;">
                    <div class="d-flex justify-content-between align-items-center info-health" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                        <h6 class="subHeaderTitle content-left my-2 text-white pl-6">
                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/health-icon-white.svg" class="iconsize" style="width: 15px;">
                            <!-- <i class="crmnew-phone iconsize-16 iconsize" style="font-size: 14px; color: #fff;"></i> -->
                            <span class="pl-2 fs-14 fw-500 profile-text">Health</span>
                        </h6>
                        <div v-if="$route.params.id == loggedInUser.user_id" style="margin-right: 10px;">
                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                <button v-if="!edit_enable" @click="showEditEnable" class="btn btn-icon pointer text-capitalize" style="padding: 3px 8px !important;background: rgb(255, 255, 255);border-radius: 3px !important;" >
                                    <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                                    <span class="ml-1 mr-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row pb-40" v-if="ajax_call_in_progress">
                        <div class="col-lg-12 py-5 px-3 mt-10">
                            <div class="flex-container">
                                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="ml-2 mr-1 mb-3" style="padding: 0 0px !important;">
                        <div class="">
                            <div class="">
                                <div class="infoHeader px-6 pt-3 pb-4 health-view">
                                    <div class="mt-1">
                                        <div class="d-flex align-items-center new-contactView">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-health defaultBlue">Blood Group</div>
                                            <div class="d-flex align-items-center w-100" :class="{readonly : !edit_enable}">
                                                <multiselect
                                                    v-model="health_data.blood_group"
                                                    :disabled="!edit_enable"
                                                    openDirection="bottom"
                                                    class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                                    :options="['A+','A-','B+','B-','AB+','AB-','O+','O-']"
                                                    :multiple="false" :searchable="true" :internal-search="true"
                                                    :clear-on-select="false" :close-on-select="true"
                                                    :options-limit="280" :max-height="200" :show-no-results="true"
                                                    :hide-selected="false"  :show-labels="false">
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">No Blood Groups Found</div>
                                                    </template>
                                                </multiselect>
                                                <!-- <div v-if="edit_blood_group == true" class="d-flex justify-content-end align-items-center">
                                                    <div class="pointer" @click="resetValue('blood_group')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="addUpdateHealth('blood_group')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div class="d-flex align-items-center new-contactView">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-health defaultBlue mt-3">
                                                Pre-Existing Conditions
                                            </div>
                                            <div class="d-flex align-items-start w-100" style="padding-top:5px;" :class="{readonly : !edit_enable}">
                                                <multiselect
                                                    v-model="health_data.pre_existing_conditions"
                                                    @open="dropdownsData('pre-existing_conditions')"
                                                    @remove="removeConditionsChip(option)"
                                                    openDirection="bottom"
                                                    :disabled="!edit_enable"
                                                    :loading="pre_loading"
                                                    class="diginew-multiselect diginew-multiselect-underline filter-multiple"
                                                    :options="pre_existing_conditions_options"
                                                    :multiple="true" :searchable="true" :internal-search="true"
                                                    :clear-on-select="true" :close-on-select="false"
                                                    @close="closeConditionsDropdowns(health_data.pre_existing_conditions, 'pre_existing_conditions')"
                                                    :show-labels="false"
                                                    :options-limit="200" :max-height="200"
                                                    :show-no-results="true" :hide-selected="false">
                                                    <template slot="tag" slot-scope="{ option }">
                                                        <span class="custom__tag">
                                                            <span class="filterSelectedName"
                                                                style="display:contents;">{{ option }}</span>
                                                                <i aria-hidden="true" v-if="edit_enable" @click="removeConditionsChip(option)" tabindex="1" class="icon icon-close fs-16 text-white custom__remove pointer"></i>
                                                        </span>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">No Conditions Found</div>
                                                    </template>
                                                    <template slot="afterList">
                                                        <div v-observe-visibility="reachedEndOfListDropdown" />
                                                    </template>
                                                    <!-- <template slot="afterList">
                                                        <div v-if="!dropdown_loading" class="add-vendor p-2 pointer fw-500">
                                                            Add Pre-Existing Conditions <span class="text-secondary fw-500">'{{"-"}}'</span>
                                                            <i class="icon icon-plus ml-2 mt-1 float-right text-secondary"></i>
                                                        </div>
                                                    </template> -->
                                                </multiselect>
                                                <!-- <div v-if="edit_pre_existing_conditions == true" class="d-flex justify-content-end align-items-center">
                                                    <div class="pointer" @click="resetValue('pre_existing_conditions')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="addUpdateHealth('pre_existing_conditions')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div class="d-flex align-items-center new-contactView">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-health defaultBlue mt-3">
                                                Allergies
                                            </div>
                                            <div class="d-flex align-items-center w-100" style="padding-top:5px;" :class="{readonly : !edit_enable}">
                                                <multiselect v-model="health_data.allergies"
                                                    @open="dropdownsData('allergies')"
                                                    @remove="removeAllergiesChip(option)"
                                                    class="diginew-multiselect diginew-multiselect-underline filter-multiple"
                                                    :loading="allergies_loading"
                                                    openDirection="bottom"
                                                    :disabled="!edit_enable"
                                                    :options="allergies_options"
                                                    :multiple="true" :searchable="true" :internal-search="true"
                                                    :clear-on-select="false" :close-on-select="false"
                                                    @close="closeConditionsDropdowns(health_data.allergies, 'allergies')"
                                                    :options-limit="200" :max-height="200"
                                                    :show-labels="false"
                                                    :show-no-results="true" :hide-selected="false">
                                                    <template slot="tag" slot-scope="{ option }">
                                                        <span class="custom__tag">
                                                            <span class="filterSelectedName"
                                                                style="display:contents;">{{ option }}</span>
                                                                <i aria-hidden="true" v-if="edit_enable" @click="removeAllergiesChip(option)" tabindex="1" class="icon icon-close fs-16 text-white custom__remove pointer"></i>
                                                        </span>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No
                                                            Results Found</div>
                                                    </template>
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">No Allergies Found</div>
                                                    </template>
                                                    <template slot="afterList">
                                                        <div v-observe-visibility="reachedEndOfListDropdown" />
                                                    </template>
                                                    <!-- <template slot="afterList">
                                                        <div v-if="!dropdown_loading" class="add-vendor p-2 pointer fw-500">
                                                            Add Allergies <span class="text-secondary fw-500">'{{"-"}}'</span>
                                                            <i class="icon icon-plus ml-2 mt-1 float-right text-secondary"></i>
                                                        </div>
                                                    </template> -->
                                                </multiselect>
                                                <!-- <div v-if="edit_allergies == true" class="d-flex justify-content-end align-items-center">
                                                    <div class="pointer" @click="resetValue('allergies')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="addUpdateHealth('allergies')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div class="d-flex align-items-center new-contactView">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-health defaultBlue mt-3">
                                                Medications
                                            </div>
                                            <div class="d-flex align-items-center w-100" style="padding-top:5px;" :class="{readonly : !edit_enable}">
                                                <multiselect v-model="health_data.medications"
                                                    @open="dropdownsData('medications')"
                                                    :loading="medication_loading"
                                                    @remove="removeMedicationsChip(option)"
                                                    class="diginew-multiselect diginew-multiselect-underline filter-multiple"
                                                    :disabled="!edit_enable"
                                                    openDirection="bottom"
                                                    :options="medications_options"
                                                    :multiple="true" :searchable="true" :internal-search="true"
                                                    :clear-on-select="false" :close-on-select="false"
                                                    :options-limit="200" :max-height="200"
                                                    @close="closeConditionsDropdowns(health_data.medications, 'medications')"
                                                    :show-labels="false"
                                                    :show-no-results="true" :hide-selected="false">
                                                    <template slot="tag" slot-scope="{ option }">
                                                        <span class="custom__tag">
                                                            <span class="filterSelectedName"
                                                                style="display:contents">{{ option }}</span>
                                                                <i aria-hidden="true" v-if="edit_enable" @click="removeMedicationsChip(option)" tabindex="1" class="icon icon-close fs-16 text-white custom__remove pointer"></i>
                                                        </span>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">No Medications Found</div>
                                                    </template>
                                                    <template slot="afterList">
                                                        <div v-observe-visibility="reachedEndOfListDropdown" />
                                                    </template>
                                                    <!-- <template slot="afterList">
                                                        <div v-if="!dropdown_loading" class="add-vendor p-2 pointer fw-500">
                                                            Add Medications <span class="text-secondary fw-500">'{{"-"}}'</span>
                                                            <i class="icon icon-plus ml-2 mt-1 float-right text-secondary"></i>
                                                        </div>
                                                    </template> -->
                                                </multiselect>
                                                <!-- <div v-if="edit_medications == true" class="d-flex justify-content-end align-items-center">
                                                    <div class="pointer" @click="resetValue('medications')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="addUpdateHealth('medications')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 mb-6">
                                        <div class="d-flex align-items-center new-contactView">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-health defaultBlue mt-3">Medical Emergency Notes</div>
                                        
                                            <div class="d-flex align-items-center w-100" style="padding-top:10px;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center"
                                                        v-if="edit_enable" style="position: absolute;top:5px;right:20px;">
                                                        <div class="pointer" @click="resetValue('notes')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addUpdateHealth('notes')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <input class="input-field-underline" style="padding-top: 6px !important; padding-right: 10px !important;" maxlength="100" :readonly="!edit_enable" v-model="health_data.notes" :class="{readonly : !edit_enable}"/>
                                                    <!-- <textarea v-model="health_data.notes"
                                                        :readonly="!edit_enable" maxlength="100"
                                                        style="padding-top: 6px !important; width: 100%; background: transparent; color: #303031; border-bottom: 1px solid rgba(0, 0, 0, 0.16); resize: none; padding: 0px 10px 0px 10px; overflow-y: auto;">
                                                    </textarea> -->
                                                    <div v-if="edit_enable" style="position: absolute;bottom: -20px;right: 0;" class="text-right text-grey"
                                                    ><span>{{health_data.notes.length}}/100</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                                    <button class="btn btn-outline-secondary" :disabled="save_api_call_in_progress" @click="hideEditEnable" style="width: 120px;border-radius: 5px;">Cancel</button>
                                    <button class="btn btn-new-success ml-5" :disabled="save_api_call_in_progress || fields_not_changed" @click="updateInfo" style="width: 120px;">Save
                                        <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import profile from "../mixins/profile";
    export default {
        props: [],
        mixins: [profile],
        components: {
            HalfCircleSpinner,
            HollowDotsSpinner
        },
        data() {
            return {
                ajax_call_in_progress: false,
                edit_blood_group: false,
                edit_notes: false,
                edit_pre_existing_conditions: false,
                edit_allergies: false,
                edit_medications: false,
                edit_user_data: [],
                blood_group_options: [],
                pre_existing_conditions_options: [],
                pre_loading: false,
                allergies_loading: false,
                medication_loading: false,
                allergies_options: [],
                medications_options: [],
                pre_existing_conditions: [],
                allergies: [],
                medications: [],
                user_data: {},
                health_data: {
                    allergies: [""],
                    blood_group: "",
                    medications: [""],
                    notes: "",
                    pre_existing_conditions: [""],
                },
                skip:0,
                limit:10,
                which_type: '',
                isVisible: false,
                scrollable: false,
                count: 0,
                edit_enable: false,
                save_api_call_in_progress: false,
                stored_health_data: null,
                stringified_response: "",
            }
        },
        methods: {
            showEditEnable() {
                this.edit_enable = true
            },
            hideEditEnable() {
                this.edit_enable = false
                this.user_data = JSON.parse(JSON.stringify(this.stored_health_data))
                if(Object.keys(this.user_data).length == 0) {
                    this.user_data = {
                        allergies: [],
                        blood_group: "",
                        medications: [],
                        notes: "",
                        pre_existing_conditions: [],
                    }
                }
                this.health_data = this.user_data;
            },
            updateInfo() {
                this.addUpdateHealth();
            },
            // editValue(obj) {
            //     if(this.$route.params.id == this.loggedInUser.user_id) {
            //         if (obj == 'blood_group') {
            //             this.edit_blood_group = true;
            //             this.edit_notes = false;
            //             this.edit_pre_existing_conditions = false;
            //             this.edit_allergies = false;
            //             this.edit_medications = false;
            //         } else if (obj == 'notes') {
            //             this.edit_blood_group = false;
            //             this.edit_notes = true;
            //             this.edit_pre_existing_conditions = false;
            //             this.edit_allergies = false;
            //             this.edit_medications = false;
            //         } else if (obj == 'pre_existing_conditions') {
            //             this.edit_blood_group = false;
            //             this.edit_notes = false;
            //             this.edit_pre_existing_conditions = true;
            //             this.edit_allergies = false;
            //             this.edit_medications = false;
            //         } else if (obj == 'allergies') {
            //             this.edit_blood_group = false;
            //             this.edit_notes = false;
            //             this.edit_pre_existing_conditions = false;
            //             this.edit_allergies = true;
            //             this.edit_medications = false;
            //         } else if (obj == 'medications') {
            //             this.edit_blood_group = false;
            //             this.edit_notes = false;
            //             this.edit_pre_existing_conditions = false;
            //             this.edit_allergies = false;
            //             this.edit_medications = true;
            //         }
            //     }
            // },
            // resetValue(obj) {
            //     let user_data = JSON.parse(localStorage.getItem('health_data_store'));
            //     if (obj == 'blood_group') {
            //         this.edit_blood_group = false;
            //         this.health_data.blood_group = user_data.blood_group == undefined || null ? '' : user_data.blood_group
            //     } else if (obj == 'notes') {
            //         this.edit_notes = false;
            //         this.health_data.notes = user_data.notes == undefined || null ? '' : user_data.notes;
            //     } else if (obj == 'pre_existing_conditions') {
            //         this.edit_pre_existing_conditions = false;
            //         this.health_data.pre_existing_conditions = user_data.pre_existing_conditions == undefined || null ? '' : user_data.pre_existing_conditions;
            //     } else if (obj == 'allergies') {
            //         this.edit_allergies = false;
            //         this.health_data.allergies = user_data.allergies == undefined || null ? '' : user_data.allergies;
            //     } else if (obj == 'medications') {
            //         this.edit_medications = false;
            //         this.health_data.medications = user_data.medications == undefined || null ? '' : user_data.medications;
            //     }
            // },
            removeConditionsChip(option) {
                this.health_data.pre_existing_conditions.forEach((el, index) => {
                    if (el == option) {
                        this.health_data.pre_existing_conditions.splice(index, 1)
                    }
                })
                this.$forceUpdate()
            },
            removeAllergiesChip(option) {
                this.health_data.allergies.forEach((el, index) => {
                    if (el == option) {
                        this.health_data.allergies.splice(index, 1)
                    }
                })
            },
            removeMedicationsChip(option) {
                this.health_data.medications.forEach((el, index) => {
                    if (el == option) {
                        this.health_data.medications.splice(index, 1)
                    }
                })
            },
            closeConditionsDropdowns(data, type) {
                if(type == 'pre_existing_conditions') {
                    this.pre_existing_conditions = data;
                } else if(type == 'allergies') {
                    this.allergies = data;
                } else if(type == 'medications') {
                    this.medications = data;
                }
            },
            async getHealthData() {
                this.ajax_call_in_progress = true;
                try {
                    let param = this.$route.params.id ? this.$route.params.id : this.loggedInUser.user_id;
                    let response = await this.getHealthInfo(param);
                    if(response.status_id == 1) {
                        localStorage.setItem('health_data_store', JSON.stringify(response.response));
                        this.edit_blood_group = false;
                        this.edit_notes = false;
                        this.edit_pre_existing_conditions = false;
                        this.edit_allergies = false;
                        this.edit_medications = false;
                        this.stored_health_data = JSON.parse(JSON.stringify(response.response))
                        this.user_data = response.response;
                        if(Object.keys(this.user_data).length == 0) {
                            this.user_data = {
                                allergies: [],
                                blood_group: "",
                                medications: [],
                                notes: "",
                                pre_existing_conditions: [],
                            }
                        }
                        this.health_data = this.user_data;
                    }
                    this.ajax_call_in_progress = false;
                    this.stringified_response = JSON.stringify(this.create_data)
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async dropdownsData(type) {
                this.which_type = type;
                if(type == 'pre-existing_conditions') {
                    // this.edit_pre_existing_conditions = true;
                    this.pre_loading = true;
                    // this.editValue('pre_existing_conditions');
                }
                else if(type == 'allergies') {
                    // this.edit_allergies = true;
                    this.allergies_loading = true;
                    // this.editValue('allergies');
                }
                else if(type == 'medications') {
                    // this.edit_medications = true;
                    this.medication_loading = true;
                    // this.editValue('medications');
                }
                try {
                    let params = {
                        type: type,
                        skip: this.skip,
                        limit: this.limit
                    };
                    let response = await this.getDropdownsData(params);
                    if(response.status_id == 1) {
                        if(type == 'pre-existing_conditions') {
                            this.pre_existing_conditions_options = response.response;
                            this.pre_loading = false;
                        }
                        else if(type == 'allergies') {
                            this.allergies_options = response.response;
                            this.allergies_loading = false;
                        }
                        else if(type == 'medications') {
                            this.medications_options = response.response;
                            this.medication_loading = false;
                        }
                    }
                    this.count = response.response.length;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async reachedEndOfListDropdown(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        if(this.which_type == 'pre-existing_conditions') {
                            this.pre_loading = true;
                        }
                        else if(this.which_type == 'allergies') {
                            this.allergies_loading = true;
                        }
                        else if(this.which_type == 'medications') {
                            this.medication_loading = true;
                        }
                        let params = {
                            limit: 10,
                            type: this.which_type,
                            skip: this.count
                        };
                        let response = await this.getDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.which_type == 'pre-existing_conditions') {
                                    tempFiles.forEach(el => {
                                        this.pre_existing_conditions_options.push(el);
                                    });
                                    this.pre_loading = false;
                                }
                                else if(this.which_type == 'allergies') {
                                    tempFiles.forEach(el => {
                                        this.allergies_options.push(el);
                                    });
                                    this.allergies_loading = false;
                                }
                                else if(this.which_type == 'medications') {
                                    tempFiles.forEach(el => {
                                        this.medications_options.push(el);
                                    });
                                    this.medication_loading = false;
                                }
                                this.count += response.response.length;
                            } else {
                                this.scrollable = true
                                this.pre_loading = false;
                                this.allergies_loading = false;
                                this.medication_loading = false;
                            }
                        }
                        this.pre_loading = false;
                        this.allergies_loading = false;
                        this.medication_loading = false;
                    }
                }
            },
            async addUpdateHealth() {
                try {
                    let params = {
                        pre_existing_conditions: [],
                        allergies: [],
                        medications: [],
                    };
                    // if(type == 'blood_group') {
                        params.blood_group = this.health_data.blood_group;
                    // } else if(type == 'pre_existing_conditions') {
                        params.pre_existing_conditions = this.health_data.pre_existing_conditions;
                    // } else if(type == 'allergies') {
                        params.allergies = this.health_data.allergies;
                    // } else if(type == 'medications') {
                        params.medications = this.health_data.medications;
                    // } else if(type == 'notes') {
                        params.notes = this.health_data.notes;
                    // }
                    if(this.health_data.id) {
                        params.id = this.health_data.id;
                    }
                    this.save_api_call_in_progress = true
                    let response = await this.updateHealthInfo(params, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.getHealthData();
                        this.edit_enable = false
                        this.save_api_call_in_progress = false
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.save_api_call_in_progress = false
                }
            }
        },
        mounted() {
            // console.log(this.userData);
            this.getHealthData();
        },
        beforeRouteLeave (to, from, next) {
            if (this.fields_not_changed == false) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        created() {
            // localStorage.setItem("store_user_data", JSON.stringify(this.userData))
            // this.edit_user_data = JSON.parse(localStorage.getItem("store_user_data"))
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            create_data() {
                return {
                    "allergies": this.health_data.allergies,
                    "blood_group": this.health_data.blood_group,
                    "medications": this.health_data.medications,
                    "notes": this.health_data.notes,
                    "pre_existing_conditions": this.health_data.pre_existing_conditions,
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    }
</script>

<style>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .contact-details-tab-view.el-tabs.el-tabs--top .el-tabs__content .el-tab-pane .tabs-content-firstblock {
        border-radius: 5px;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cdd2da;
        background-color: #ffffff;
        margin: 5px 10px 15px;
    }

    .contact-details-tab-view.el-tabs.el-tabs--top .el-tabs__content .el-tab-pane .tabs-content-secondblock {
        border-radius: 5px;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cdd2da;
        background-color: #ffffff;
        margin: 5px 10px 15px;
    }

    textarea.textarea-control {
        height: auto;
    }

    textarea.textarea-control {
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #4e5764;
        background-color: #fff;
        background-image: none;
        border: 1px solid #cdd2da;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        -webkit-transition: border-color ease-in-out 0.15s,
            -webkit-box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s,
            -webkit-box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
            -webkit-box-shadow ease-in-out 0.15s;
    }

    .input-group.digi-input-group {
        border: 1px solid #cdd2da !important;
    }

    .input-group.digi-input-group>.form-control {
        position: relative !important;
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
        width: 1% !important;
        margin-bottom: 0 !important;
        height: 22px !important;
        padding: 6px 12px 6px 2px !important;
        color: #4e5764;
        text-decoration: underline;
        border-bottom: 1px solid transparent !important;
    }

    .input-group.digi-input-group .input-group-addon {
        padding-left: 0rem !important;
        padding-right: 0.2rem !important;
        border-bottom: 1px solid transparent !important;
        padding-top: 2px;
        cursor: pointer;
    }

    .qrcode-block-view-account {
        border-radius: 6px;
        width: 104px;
        height: 105px;
        position: relative;
        display: inline-block;
        display: block;
        border: 1px solid #0d84f2;
        background: #d6e2ef;
    }

    .qrcode-content-view-account {
        text-align: center;
        margin: 6px auto;
        background: #d6e2ef !important;
    }

    .qr-logo-view-account {
        position: absolute;
        margin-top: 40px;
        margin-left: 35px;
        width: 30px;
    }
    .multiselect--disabled .multiselect__tags {
        background: transparent !important;
        pointer-events: none !important;
        opacity: 1;
    }
    .multiselect--disabled {
        background: #fff !important;
        pointer-events: none;
        opacity: .6;
    }
@media only screen and (min-width: 0px) and (max-width: 350px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .health-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-health{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -20px !important;
    }
    .infoHeader{
        width: 100% !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
}
@media only screen and (min-width:351px) and (max-width: 400px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .health-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-health{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -20px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:401px) and (max-width: 500px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .health-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-health{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -20px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:501px) and (max-width: 768px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .health-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-health{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -20px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
.readonly{
    cursor: no-drop !important;
}
.btn-new-success:disabled{
    cursor: no-drop !important;
}
.btn:disabled{
    pointer-events: auto !important
}
</style>